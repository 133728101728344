import React, { useState, useEffect } from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ContactInfo from "../components/Contact/ContactInfo"
import ContactForm from "../components/Contact/ContactForm"
import axios from "axios"
import SEO from "../components/App/SEO"

const Contact = () => {
  const [Broker, setBroker] = useState(null)
  useEffect(() => {
    const brokerId = new URL(window.location.href).searchParams.get("broker")
    if (brokerId) {
      axios
        .get(`${process.env.GATSBY_BACKEND_API_URL}/brokers/${brokerId}`)
        .then(results => {
          setBroker(results.data)
        })
        .catch(err => {
          setBroker(null)
        })
    }
  }, [])
  function clearBrokerState() {
    console.log("called")
    setBroker(null)
  }
  return (
    <Layout>
      <SEO
        title="Contact Us | Oxygen - Home Loans Made Simple"
        description="Send us a message and we'll get back to you shortly."
      />
      <Navbar clearBrokerState={clearBrokerState} />
      <PageBanner
        pageTitle={
          Broker
            ? "Contact " + Broker.firstName + " " + Broker.lastName
            : "Contact Us"
        }
        pageSubTitle={
          Broker
            ? Broker.firstName +
              " " +
              Broker.lastName +
              " will get back to you shortly."
            : "Send us a message and we'll get back to you shortly."
        }
        pageImage={Broker ? Broker.brokerImage : null}
      />
      <div className="container frm-contact pb-100">
        <div className="row">
          <div className="col-lg-8 col-12 pr-5">
            <ContactForm Broker={Broker} />
          </div>
          <div className="col-lg-4 col-12">
            <ContactInfo />
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Contact

import React, { useState, useEffect } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import axios from "axios"
import Success from "../Common/FormSuccess"
import Loader from "../Index/Loader"

const ContactForm = props => {
  const [SubmitSuccess, setSubmitSuccess] = useState(false)
  const [request, setRequest] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (SubmitSuccess) {
      const timer = setTimeout(() => {
        setSubmitSuccess(false)
      }, 4000)
      return () => clearTimeout(timer)
    }
  }, [SubmitSuccess])

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    subject: "What are you interested in?",
    postalCode: "",
    requestedDate: "",
    requestedTimeSlot: "",
    subscribeToNewsletter: false,
  }

  const ValidateEmail = value => {
    let error
    if (!value) {
      return (error = "Required")
    }
    const RegEx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!RegEx.test(value)) {
      error = "Invalid Email"
    }
    return error
  }

  const ValidateName = value => {
    let error
    if (!value) {
      return (error = "Required")
    }
    const RegEx = /^[a-zA-Z ]{2,30}$/
    if (!RegEx.test(value)) {
      error = "Invalid Name"
    }
    return error
  }

  const ValidatePostCode = value => {
    let error
    if (!value) {
      return (error = "Required")
    }
    const RegEx = /^[0-9]{4}$/
    if (!RegEx.test(value)) {
      error = "Invalid Postcode"
    }
    return error
  }

  const ValidatePhone = value => {
    let error
    if (value.length == 12) {
      if (!value.startsWith("+")) {
        return (error = "Invalid Phone Number")
      } else {
        value = value.slice(1)
        return (error = !/^\d{11}$/.test(value) ? "Invalid Phone Number" : "")
      }
    } else if (value.length == 10) {
      const RegEx = /^\d{10}$/
      if (!RegEx.test(value)) {
        error = "Invalid Phone Number"
      }
    } else {
      return (error = "Invalid Phone Number")
    }
    return error
  }

  const ValidateMessage = value => {
    let error
    if (!value) {
      return error
    }
    if (value.length > 500) {
      error = "Maximum Character Limit Exceeded"
    }
    return error
  }

  const ValidateSubject = value => {
    let error
    if (!value || value === "What are you interested in?") {
      return (error = "Required")
    }
  }

  const onSubmit = async (values, { resetForm }) => {
    if (props.Broker) {
      values.agentId = props.Broker.id
      values.agentEmail = props.Broker.email
      values.agentName = props.Broker.firstName
    }
    if (!request) {
      try {
        setRequest(true)
        setLoading(true)
        const res = await axios.post(
          `${process.env.GATSBY_BACKEND_API_URL}/appointments/contacts`,
          values,
          {}
        )
        setSubmitSuccess(true)
        resetForm(initialValues)
        if (window.pageYOffset !== 0) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
        setRequest(false)
        setLoading(false)
      } catch (error) {
        setSubmitSuccess(false)
        setLoading(false)
      }
    }
  }

  return (
    <section className="contact-area mt-3">
      {SubmitSuccess ? <Success /> : null}
      {loading ? <Loader /> : null}
      <div className="contact-form">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <Form id="contactForm">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>First Name</label>
                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="form-control"
                    placeholder="Your first name"
                    validate={ValidateName}
                  />
                  <ErrorMessage name="firstName">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Last Name</label>
                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="form-control"
                    placeholder="Your last name"
                    validate={ValidateName}
                  />
                  <ErrorMessage name="lastName">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Mobile Phone</label>
                  <Field
                    type="tel"
                    id="phone"
                    name="phone"
                    className="form-control"
                    placeholder="Your mobile number"
                    validate={ValidatePhone}
                  />
                  <ErrorMessage name="phone">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>E-mail</label>
                  <Field
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="you@example.com"
                    validate={ValidateEmail}
                  />
                  <ErrorMessage name="email">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Postcode</label>
                  <Field
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    className="form-control"
                    placeholder="Your postcode"
                    validate={ValidatePostCode}
                  />
                  <ErrorMessage name="postalCode">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Subject</label>
                  <Field
                    as="select"
                    name="subject"
                    id="subject"
                    className="form-control"
                    validate={ValidateSubject}
                    placeholder="sdasdasd"
                  >
                    <option disabled>What are you interested in?</option>
                    <option value="Lower rate on my current Home Loan">
                      Lower rate on my current Home Loan
                    </option>
                    <option value="New Home Loan">New Home Loan</option>
                    <option value="New Investment Loan">
                      New Investment Loan
                    </option>
                    <option value="Other">Other</option>
                  </Field>
                  <ErrorMessage name="subject">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Message</label>
                  <Field
                    as="textarea"
                    name="message"
                    id="message"
                    className="form-control"
                    placeholder="How can we help?"
                    rows="5"
                    validate={ValidateMessage}
                  />
                  <ErrorMessage name="message">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
              <div className="col-12">
                <div className="remember-me-wrap">
                  <p>
                    <Field
                      type="checkbox"
                      id="subscribeToNewsletter"
                      name="subscribeToNewsletter"
                      className="form-control"
                    />
                    <label>
                      Subscribe to our newsletter to receive offers and updates.
                    </label>
                  </p>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <button
                  type="submit"
                  className="default-btn primary-btn disabled"
                >
                  Send Message <span></span>
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </section>
  )
}

export default ContactForm
